<template>
  <main v-if="useCustom">
    <div class="p-6 pt-10 md:p-8 md:pt-12 flex items-center justify-between">
      <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
        >Salary</span
      >
      <div v-if="route.path.includes('edit')" class="flex items-center gap-x-8">
        <easiButton
          v-if="route.path.includes('edit')"
          @click="updateView('benefits')"
          color="primary"
          variant="outlined"
          size="small"
          class="py-1"
        >
          <span class="flex items-center text-base gap-3">
            <img class="w-4" src="@/assets/icons/edit-primary.svg" alt="" />

            Edit
          </span>
        </easiButton>
      </div>
    </div>

    <div class="grid grid-cols-2 text-white">
      <div
        class="relative col-span-2 md:col-span-1 p-6 sm:px-10 sm:py-8 bg-highlight flex flex-col gap-1"
      >
        <div class="absolute top-0 right-0">
          <Tag
            v-if="
              employee && employee.prorate && employee.prorate.proratedSalary
            "
            style="width: 80px"
            severity="info"
            class="text-xs"
          >
            <span class="text-xs">PRORATED</span></Tag
          >
        </div>
        <span class="font-bold text-base text-grey sm:text-lg">Gross:</span>
        <span class="text-2xl sm:text-4xl text-primary-deep font-bold">{{
          formatAmount(employee && employee.staffLevel.grossAmount)
        }}</span>
      </div>
      <div
        class="col-span-2 md:col-span-1 p-6 sm:px-10 sm:py-8 bg-secondary-crepe flex flex-col gap-1"
      >
        <span class="font-bold text-secondary text-base sm:text-lg">Net:</span>
        <span class="text-2xl sm:text-4xl text-secondary font-bold">{{
          employee && employee.prorate && netOrProrate && netOrProrate > 0
            ? formatAmount(netOrProrate)
            : formatAmount(employee && employee.staffLevel.netAmount)
        }}</span>
      </div>
    </div>

    <!-- //// Benefits ///// -->
    <div class="">
      <span
        v-if="route.path.includes('edit') && benefits.length"
        @click="showWarningForAllowance"
        class="cursor-pointer flex justify-end mb-0 pr-8 pt-6"
        ><i class="fas fa-trash text-primary"></i>
      </span>
      <div class="p-6 sm:px-10 sm:py-8 grid grid-cols-2 sm:grid-cols-4 gap-4">
        <div
          v-for="allowance in benefits"
          :key="allowance.allowanceId"
          class="flex flex-col col-span-1"
        >
          <div class="flex items-center gap-x-4">
            <span class="text-sm text-dark-800 font-medium capitalize">{{
              allowance && allowance.name
            }}</span>
            <input
              v-if="
                route.path.includes('edit') &&
                !staticAllowances.includes(allowance.name)
              "
              :checked="selectedAllowances.includes(allowance._id)"
              @change="selectAllowance(allowance._id)"
              type="checkbox"
              name=""
              id=""
            />
          </div>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                allowance && removeSymbolsFromString(allowance.amount)
              )
            }}
          </span>
        </div>
      </div>
    </div>

    <!-- ///Bonus/// -->
    <div
      class="px-6 py-2 sm:px-10 text-base font-bold bg-dark-100 text-dark-800 flex items-center justify-between"
    >
      Bonus
    </div>
    <div class="p-6 sm:px-10 sm:py-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div
        v-for="bonus in employee.bonusOrDeductions"
        v-show="bonus.type && bonus.type.toLowerCase() == 'bonus'"
        :key="bonus._id"
        class="flex flex-col"
      >
        <div class="flex items-center gap-x-4">
          <div class="font-normal text-secondary text-base capitalize">
            {{ bonus.name }}
          </div>
        </div>

        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium"
            >{{ bonus.amount ? "Amount" : "Percentage" }}:</span
          >
          <span class="text-base text-dark-800 font-bold">
            {{
              bonus.amount ? formatAmount(bonus.amount) : bonus.percentage
            }}</span
          >
        </div>
      </div>
    </div>

    <!-- ////Statutory Deduction ///// -->
    <div
      class="px-6 py-2 sm:px-10 text-base font-bold bg-dark-100 text-dark-800 flex items-center justify-between"
    >
      Statutory Deductions
      <div v-if="route.path.includes('edit')" class="flex items-center gap-x-8">
        <easiButton
          v-if="route.path.includes('edit')"
          @click="updateView('deductions')"
          size="small"
          color="primary"
          variant="outlined"
          class="py-1"
        >
          <span class="flex items-center text-base mr-2 gap-3">
            <img class="w-4" src="@/assets/icons/edit-primary.svg" alt="" />

            Edit
          </span>
        </easiButton>
        <span @click="showWarning" class="hidden cursor-pointer"
          ><i class="fas fa-trash text-primary"></i>
        </span>
      </div>
    </div>
    <!-- ///PAYE//// -->
    <div class="p-6 sm:px-10 sm:py-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div
        class="flex flex-col"
        v-if="
          employee &&
          employee.staffLevel.paye &&
          employee.staffLevel.paye.amount
        "
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base">PAYE</div>
          <input
            v-if="route.path.includes('edit')"
            class="hidden"
            :checked="selectedDeductions.includes('paye')"
            @change="selectDeduction('paye')"
            type="checkbox"
            name=""
            id=""
          />
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-500">PAYE ID:</span>
          <span class="text-base text-dark-800 font-medium"
            >{{ employee && employee.staffLevel.paye.taxId }}
          </span>
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Amount:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(employee && employee.staffLevel.paye.amount)
            }}</span
          >
        </div>
      </div>

      <!-- ///PENSION//// -->
      <div
        class="flex flex-col"
        v-if="
          employee &&
          employee.staffLevel.pension &&
          employee.staffLevel.pension.amount
        "
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base">Pension</div>
          <input
            v-if="route.path.includes('edit')"
            class="hidden"
            :checked="selectedDeductions.includes('pension')"
            @change="selectDeduction('pension')"
            type="checkbox"
            name=""
            id=""
          />
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Provider:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              employee &&
              employee.staffLevel.pension &&
              employee.staffLevel.pension.provider
            }}</span
          >
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">ID:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              employee &&
              employee.staffLevel.pension &&
              employee.staffLevel.pension.employeePensionPin
            }}</span
          >
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Amount:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.pension &&
                  employee.staffLevel.pension.amount
              )
            }}</span
          >
        </div>

        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium"
            >Employee pension Amount:</span
          >
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.pension &&
                  employee.staffLevel.pension.employeePensionContribution
              )
            }}</span
          >
        </div>

        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium"
            >Employer pension Amount:</span
          >
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.pension &&
                  employee.staffLevel.pension.employerPensionContribution
              )
            }}</span
          >
        </div>
      </div>

      <!-- ///NFH//// -->
      <div
        class="flex flex-col"
        v-if="
          employee && employee.staffLevel.nhf && employee.staffLevel.nhf.amount
        "
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base">NHF</div>
          <input
            v-if="route.path.includes('edit')"
            class="hidden"
            :checked="selectedDeductions.includes('nhf')"
            @change="selectDeduction('nhf')"
            type="checkbox"
            name=""
            id=""
          />
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Code:</span>
          <span class="text-base text-dark-800 font-bold"></span>
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Amount:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.nhf &&
                  employee.staffLevel.nhf.amount
              )
            }}</span
          >
        </div>
      </div>
      <!-- ///HMO//// -->
      <div
        class="flex flex-col"
        v-if="
          employee && employee.staffLevel.hmo && employee.staffLevel.hmo.amount
        "
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base">HMO</div>
          <input
            v-if="route.path.includes('edit')"
            class="hidden"
            :checked="selectedDeductions.includes('hmo')"
            @change="selectDeduction('hmo')"
            type="checkbox"
            name=""
            id=""
          />
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Provider:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              employee &&
              employee.staffLevel.hmo &&
              employee.staffLevel.hmo.provider
            }}</span
          >
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Amount:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.hmo &&
                  employee.staffLevel.hmo.amount
              )
            }}</span
          >
        </div>
      </div>
      <!-- ///LIFE INSURANCE//// -->
      <div
        class="flex flex-col"
        v-if="
          employee &&
          employee.staffLevel.lifeInsurance &&
          employee.staffLevel.lifeInsurance.amount
        "
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base">Life Insurance</div>
          <input
            v-if="route.path.includes('edit')"
            class="hidden"
            :checked="selectedDeductions.includes('lifeInsurance')"
            @change="selectDeduction('lifeInsurance')"
            type="checkbox"
            name=""
            id=""
          />
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Provider:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              employee &&
              employee.staffLevel.lifeInsurance &&
              employee.staffLevel.lifeInsurance.provider
            }}</span
          >
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Duration:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              employee &&
              employee.staffLevel.lifeInsurance &&
              employee.staffLevel.lifeInsurance.duration
            }}</span
          >
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Amount:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.lifeInsurance &&
                  employee.staffLevel.lifeInsurance.amount
              )
            }}</span
          >
        </div>
      </div>

      <!-- ///LIFE ASSURANCE//// -->
      <div
        class="flex flex-col"
        v-if="
          employee &&
          employee.staffLevel.lifeAssurance &&
          employee.staffLevel.lifeAssurance.amount
        "
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base">Life Assurance</div>
          <input
            v-if="route.path.includes('edit')"
            class="hidden"
            :checked="selectedDeductions.includes('lifeAssurance')"
            @change="selectDeduction('lifeAssurance')"
            type="checkbox"
            name=""
            id=""
          />
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Provider:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              employee &&
              employee.staffLevel.lifeAssurance &&
              employee.staffLevel.lifeAssurance.provider
            }}</span
          >
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800">Duration:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              employee &&
              employee.staffLevel.lifeAssurance &&
              employee.staffLevel.lifeAssurance.duration
            }}</span
          >
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Amount:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.lifeAssurance &&
                  employee.staffLevel.lifeAssurance.amount
              )
            }}</span
          >
        </div>
      </div>

      <!-- ///NSITF//// -->
      <div
        class="flex flex-col"
        v-if="
          employee &&
          employee.staffLevel.nsitf &&
          employee.staffLevel.nsitf.amount
        "
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base">NSITF</div>
          <input
            v-if="route.path.includes('edit')"
            class="hidden"
            :checked="selectedDeductions.includes('nsitf')"
            @change="selectDeduction('nsitf')"
            type="checkbox"
            name=""
            id=""
          />
        </div>

        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Amount:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.nsitf &&
                  employee.staffLevel.nsitf.amount
              )
            }}</span
          >
        </div>
      </div>
      <div
        class="flex flex-col"
        v-if="
          employee && employee.staffLevel.itf && employee.staffLevel.itf.amount
        "
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base">ITF</div>
          <input
            v-if="route.path.includes('edit')"
            class="hidden"
            :checked="selectedDeductions.includes('itf')"
            @change="selectDeduction('itf')"
            type="checkbox"
            name=""
            id=""
          />
        </div>

        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Amount:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.itf &&
                  employee.staffLevel.itf.amount
              )
            }}</span
          >
        </div>
      </div>

      <div
        class="flex flex-col"
        v-if="
          employee && employee.staffLevel.wht && employee.staffLevel.wht.amount
        "
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base">WHT</div>
          <input
            v-if="route.path.includes('edit')"
            class="hidden"
            :checked="selectedDeductions.includes('wht')"
            @change="selectDeduction('wht')"
            type="checkbox"
            name=""
            id=""
          />
        </div>

        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Amount:</span>
          <span class="text-base text-dark-800 font-bold">
            {{
              formatAmount(
                employee &&
                  employee.staffLevel.wht &&
                  employee.staffLevel.wht.amount
              )
            }}</span
          >
        </div>

        <div
          v-if="
            employee.staffLevel &&
            employee.staffLevel.wht &&
            employee.staffLevel.wht.percentage &&
            Number(employee.staffLevel.wht.percentage) > 0
          "
          class="flex items-center gap-2"
        >
          <span class="text-sm text-dark-800 font-medium">Percentage:</span>
          <span class="text-base text-dark-800 font-bold">
            {{ employee.staffLevel.wht.percentage }}</span
          >
        </div>
        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium">Payment Types:</span>
          <span class="text-base text-dark-800 font-bold">
            {{ employee.staffLevel.wht.paymentTypes.join(",") }}</span
          >
        </div>
      </div>
    </div>

    <!-- ///Deduction/// -->
    <div
      class="px-6 py-2 sm:px-10 text-base font-bold bg-dark-100 text-dark-800 flex items-center justify-between"
    >
      Deductions
    </div>
    <div class="p-6 sm:px-10 sm:py-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div
        v-for="deduction in employee.bonusOrDeductions"
        v-show="deduction.type && deduction.type.toLowerCase() == 'deduction'"
        :key="deduction._id"
        class="flex flex-col"
      >
        <div class="flex items-center gap-x-4">
          <div class="font-medium text-secondary text-base capitalize">
            {{ deduction.name }}
          </div>
        </div>

        <div class="flex items-center gap-2">
          <span class="text-sm text-dark-800 font-medium"
            >{{ deduction.amount ? "Amount" : "Percentage" }}:</span
          >
          <span
            v-if="deduction.employeeIds.includes($route.params.id)"
            class="text-base text-dark-800 font-bold"
          >
            {{
              deduction.amount
                ? formatAmount(deduction.amount)
                : deduction.percentage
            }}</span
          >
          <span class="text-base text-dark-800 font-medium" v-else>N/A</span>
        </div>
      </div>
    </div>
    <!-- //// Bank ///// -->
    <div
      class="px-6 py-2 sm:px-10 text-base font-bold bg-dark-100 text-dark-800 flex items-center justify-between"
    >
      Bank details

      <easiButton
        v-if="route.path.includes('edit')"
        @click="updateView('bank')"
        size="small"
        color="primary"
        variant="outlined"
        class="py-1"
      >
        <span class="flex items-center text-base mr-2 gap-3">
          <img class="w-4" src="@/assets/icons/edit-primary.svg" alt="" />

          Edit
        </span>
      </easiButton>
    </div>
    <div class="p-6 sm:px-10 sm:py-8">
      <div class="flex gap-4 flex-col">
        <div class="flex items-center gap-4">
          <span class="text-sm text-dark-500">Bank Name:</span>
          <span
            class="text-base font-bold text-dark-800 font-medium flex items-center gap-2 capitalize"
          >
            <div
              class="w-8 h-8 rounded-full border border-dark-100 overflow-hidden"
            >
              <img
                :src="bankLogo"
                :alt="employee && employee.payment.bankName"
              />
            </div>
            {{ employee && employee.payment.bankName }}</span
          >
        </div>
        <div class="flex items-center gap-4">
          <span class="text-sm text-dark-500">Account Number:</span>
          <span class="text-base text-dark-800 font-bold">{{
            employee && employee.payment.accountNumber
          }}</span>
        </div>
        <div class="flex items-center gap-4">
          <span class="text-sm text-dark-500">Account Name:</span>
          <span class="text-base text-dark-800 font-bold capitalize">{{
            employee && employee.payment.accountName
          }}</span>
        </div>
      </div>
    </div>

    <!-- //// Prorate ///// -->
    <div
      v-if="employee && employee.prorate && employee.prorate.proratedSalary"
      class="px-6 py-2 sm:px-10 text-base font-bold bg-dark-100 text-dark-800 flex items-center justify-between"
    >
      Proration

      <span
        @click="showWarningForProration"
        class="cursor-pointer flex justify-end mb-0 pr-8"
        ><i class="fas fa-trash text-secondary"></i>
      </span>
    </div>
    <div
      v-if="employee && employee.prorate && employee.prorate.proratedSalary"
      class="p-6 sm:px-10 sm:py-8"
    >
      <div class="flex flex-col gap-y-3">
        <div class="flex items-center gap-4">
          <span class="text-sm text-dark-500">Month:</span>
          <span
            class="text-base text-dark-800 font-medium flex items-center gap-2 capitalize"
          >
            {{ getMonthByIndex(employee.prorate.month) }}</span
          >
        </div>
        <div class="flex items-center gap-4">
          <span class="text-sm text-dark-500">Year:</span>
          <span class="text-base text-dark-800 font-medium">{{
            employee && employee.prorate.year
          }}</span>
        </div>
        <div class="flex items-center gap-4">
          <span class="text-sm text-dark-500">Days:</span>
          <span class="text-base text-dark-800 font-medium capitalize">{{
            employee && employee.prorate.days
          }}</span>
        </div>

        <div class="flex items-center gap-4">
          <span class="text-sm text-dark-500">Daily salary:</span>
          <span class="text-base text-dark-800 font-medium capitalize">{{
            formatAmount(employee && employee.prorate.dailySalary)
          }}</span>
        </div>
      </div>
    </div>

    <easiWarn v-if="deleteWarning === true" @close="deleteWarning = false">
      <template v-slot:message>
        <span
          >You are about to delete deductions for this employee, please note
          that this action is irreversible</span
        >
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="px-0 text-sm font-bold text-dark-800 leading-6">
            Do you wish to continue?
          </div>

          <div class="flex items-center w-full gap-x-4">
            <easiButton
              class="w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >No</easiButton
            >
            <easiButton
              class="w-full"
              :loading="loading"
              @click="deleteDeductions"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiWarn
      v-if="deleteWarningForAllowance === true"
      @close="deleteWarningForAllowance = false"
    >
      <template v-slot:message>
        <span
          >You are about to delete allowance(s) for this employee, please not
          that this action is irreversible</span
        >
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="px-0 text-sm font-bold text-dark-800 leading-6">
            Do you wish to continue?
          </div>

          <div class="flex items-center w-full gap-x-4">
            <easiButton
              @click="deleteWarningForAllowance = false"
              variant="outlined"
              >No</easiButton
            >
            <easiButton :loading="loading" @click="deleteAllowanceDetails"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiWarn
      v-if="deleteWarningForProrate === true"
      @close="deleteWarningForProrate = false"
    >
      <template v-slot:message>
        <span
          >You are about to delete proration for this employee, please note that
          this action is irreversible</span
        >
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="px-0 text-sm font-bold text-dark-800 leading-6">
            Do you wish to continue?
          </div>

          <div class="flex items-center w-full gap-x-4">
            <easiButton
              @click="deleteWarningForProrate = false"
              variant="outlined"
              >No</easiButton
            >
            <easiButton
              :loading="deleteProration"
              @click="deleteProrate(employee)"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Employee deduction(s) deleted successfully</span>
      </template>
    </easiSuccess>

    <easiSuccess
      v-if="updateAllowanceSuccess"
      @close="updateAllowanceSuccess = false"
    >
      <template v-slot:message>
        <span>Employee allowance(s) deleted successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="deleteProration" />
  </main>

  <BenefitScreen
    :type="type"
    @view="updateView($event)"
    :fromEmployee="true"
    v-else
  />
</template>

<script setup>
import { computed, reactive, ref, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";
import { useToast } from "vue-toastification";
import BenefitScreen from "@/components/Payroll/View/Benefits/Benefits.vue";

import { storeToRefs } from "pinia";
import banks from "@/utils/banks";

const props = defineProps({
  type: {
    type: String,
    default: "view",
  },
});

const emit = defineEmits(["view"]);

const { formatAmount } = helperFunctions;
const store = useDataStore();
const route = useRoute();
const toast = useToast();

const { listAllowance } = storeToRefs(store);
const { query, mutate } = store;

const bonusDeduction = computed(() => store.getBonusAndDeduction);
const fromArchived = computed(() => store.getViewFromArchived);
const useCustom = ref(false);
const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);
const staffLevelId =
  (employee.value &&
    employee.value.staffLevel &&
    employee.value.staffLevel._id) ||
  "";
const netOrProrate = computed(() => {
  if (
    employee.value &&
    employee.value.prorate &&
    Array.isArray(employee.value.prorate) &&
    employee.value.prorate.length &&
    employee.value.prorate[0].staffLevel
  ) {
    return employee.value.prorate[0].staffLevel.netAmount;
  } else if (
    employee.value &&
    employee.value.prorate &&
    !Array.isArray(employee.value.prorate) &&
    employee.value.prorate.staffLevel
  ) {
    return employee.value.prorate.staffLevel.netAmount;
  } else {
    return 0;
  }
});
const deleteProration = ref(false);

function getMonthByIndex(index) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[index];
}
const deleteWarningForProrate = ref(false);
const showWarningForProration = () => {
  deleteWarningForProrate.value = true;
};
const bonusData = ref([]);
const deductionData = ref([]);

const arr = [
  "paye",
  "pension",
  "nhf",
  "nsitf",
  "itf",
  "lifeInsurance",
  "lifeAssurance",
  "hmo",
];

const loading = ref(false);
const staticAllowances = ref(["basic", "housing", "transport"]);
const updateSuccess = ref(false);
const updateAllowanceSuccess = ref(false);
const deleteWarning = ref(false);
const deleteWarningForAllowance = ref(false);
const selectedDeductions = ref([]);
const deduction = ref([]);

const selectedAllowances = ref([]);
const allowances = ref([]);

function removeSymbolsFromString(string) {
  if (string && String(string).length) {
    let result = String(string).replace(/[₦%,]/g, "");
    return Number(result);
  }
  return 0;
}

onMounted(() => {
  queryAllowance();
});

const queryAllowance = async () => {
  await query({
    endpoint: "ListAllowance",
    payload: {
      paging: {
        limit: 1000,
        skip: 0,
        search: null,
      },
    },
    service: "PAYROLL",
    storeKey: "allowance",
  });
};

const selectAllowance = (id) => {
  if (selectedAllowances.value.includes(id)) {
    let index = selectedAllowances.value.indexOf(id);
    if (index > -1) {
      selectedAllowances.value.splice(index, 1);
    }
  } else {
    selectedAllowances.value.push(id);
  }
};

const selectDeduction = (ben) => {
  if (selectedDeductions.value.includes(ben)) {
    let index = selectedDeductions.value.indexOf(ben);
    if (index > -1) {
      selectedDeductions.value.splice(index, 1);
    }
  } else {
    selectedDeductions.value.push(ben);
  }
};

const showWarningForAllowance = () => {
  if (selectedAllowances.value.length) {
    deleteWarningForAllowance.value = true;
  } else {
    toast.error("You have to select at least one allowance to be deleted.");
  }
};

const showWarning = () => {
  if (selectedDeductions.value.length) {
    deleteWarning.value = true;
  } else {
    toast.error("You have to select at least one deduction to be deleted.");
  }
};

const processDeductions = () => {
  deduction.value = selectedDeductions.value.map((item) => {
    if (item === "paye") return "PAYE";
    if (item === "pension") return "Pension";
    if (item === "nhf") return "NHF";
    if (item === "nsitf") return "NSITF";
    if (item === "itf") return "ITF";
    if (item === "wht") return "WHT";
    if (item === "lifeInsurance") return "Group_Life_Insurance";
    if (item === "lifeAssurance") return "Employee_Life_Assurance";
    if (item === "hmo") return "HMO";
    if (item === "businessExpense") return "Business_Expense";
    if (item === "gratuity") return "Gratuity";
  });
};

const payload = ref({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

const queryEmployees = async () => {
  // loading.value.table = true;
  try {
    await query({
      endpoint: "ListEmployees",
      payload: payload.value,
      service: "EMP",
      storeKey: "listEmployees",
    });
  } catch (err) {
    // loading.value.table = false;
    console.log(err);
  }
};

const deleteDeductions = async () => {
  try {
    processDeductions();
    loading.value = true;
    let res = await mutate({
      endpoint: "DeleteDeductions",
      service: "PAYROLL",
      data: {
        input: {
          deduction: deduction.value,
          staffLevelId,
        },
      },
    });

    if (res.success) {
      await queryEmployees();
      deleteWarning.value = false;
      updateSuccess.value = true;
      setTimeout(() => {
        updateSuccess.value = false;
      }, 1000);
      // toast.success("Bank Record Updated Successfully");
    }
  } catch (e) {
    console.log(e);
  } finally {
    loading.value = false;
  }
};

const deleteAllowanceDetails = () => {
  loading.value = true;
  mutate({
    endpoint: "deleteAllowanceDetails",
    service: "EMP",
    data: {
      input: {
        allowanceDetailsIds: selectedAllowances.value,
        staffLevelId,
      },
    },
  })
    .then((res) => {
      console.log(res, "RES");
      if (res[0]._id.length) {
        deleteWarningForAllowance.value = false;
        updateAllowanceSuccess.value = true;
        loading.value = false;
        // toast.success("Bank Record Updated Successfully");
      }
    })
    .catch((e) => console.log(e));
};

const benefits = computed(() => {
  const arr = [];
  employee.value.staffLevel.allowanceDetails.forEach((el) => {
    el.name = getAllowanceName(el.allowanceId);
    el.amount = getAllowanceAmount(el);
    arr.push(el);
  });

  return arr;
});
// log(employee.value);
// log(benefits.value);

const bankLogo = computed(() => {
  let bank = banks.find(
    (el) => el.code === String(employee && employee.value.payment.bankCode)
  );

  return bank.logo;
});

function getAllowanceName(id) {
  const allowance = listAllowance.value.data.find((el) => el._id === id);
  return allowance ? allowance.name : "";
}

function getAllowanceAmount(allowance) {
  if (allowance.amount) {
    // return formatAmount(removeSymbolsFromString(allowance.amount));
    return removeSymbolsFromString(allowance.amount);
  } else {
    const amount =
      (employee.value &&
        employee.value.staffLevel.grossAmount *
          removeSymbolsFromString(allowance.percentage)) / 100;
    // return formatAmount(amount);
    return amount;
  }
}

function updateView(view) {
  emit("view", view);
}
// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
const queryBonus = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Bonus",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    bonusData.value = bonusDeduction.value;
  } catch (err) {
    console.log(err);
  }
};

const queryDeduction = async () => {
  try {
    await query({
      endpoint: "ListBonusAndDeduction",
      payload: {
        input: {
          paging: {
            limit: 1000,
            skip: 0,
            search: "",
          },
          type: "Deduction",
        },
      },
      service: "PAYROLL",
      storeKey: "listBonusAndDeduction",
    });
    deductionData.value = bonusDeduction.value;
  } catch (err) {
    console.log(err);
  }
};
async function deleteProrate(data) {
  const { _id, prorate } = data;

  try {
    deleteProration.value = true;
    let res = await mutate({
      endpoint: "DeleteProrate",
      data: {
        input: {
          employeeIds: [_id],
          year: prorate.year,
          month: prorate.month,
        },
      },
      service: "PAYROLL",
    });
    if (res) {
      await queryEmployees();
      deleteWarningForProrate.value = false;
    }
  } catch (e) {
    console.log(e);
  } finally {
    deleteProration.value = false;
  }
}
queryBonus();
queryDeduction();

function extractIds() {
  if (bonusData.value.length) {
    const data = bonusData.value.map((bd) => bd.employeeIds).map((id) => id);
    console.log(data);
  }
}
extractIds();
</script>

<style></style>
